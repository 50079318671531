import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { ProdutorPF } from '@/models/ProdutorPF'
import { ProdutorPJ } from '@/models/ProdutorPJ'
import { Produtor } from '@/models/Produtor'
// import router from '../../../Routes/router'
import Swal from 'sweetalert2'
import { produtorTransformer } from '@/utils/transformers'
// import { v4 } from 'uuid'
// import { validarCNPJ, validarCPF } from '../../utils/validacoes'
export const SET_STATE = 'SET_STATE'
export const NOVA_FAZENDA = 'NOVA_FAZENDA'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_FAZENDAS = 'LOAD_FAZENDAS'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'

const getDefaultState = () => {
  return {
    fazendas: [],
    dialog: false,
    picked: '',
    loading: true,
    cadastros: [],
    isEditing: false,
    isProdutor: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [NOVA_FAZENDA] (state) {
    state.cadastros.push({
      cod_produtor_cliente: null,
      cod_produtor_fornecedor: null,
      fazenda_id: null,
      tecnico_id: null,
    })
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit, rootState }, { id }) {
    commit(RESET_STATE)
    await dispatch(LOAD_FAZENDAS)

    if (id) {
      commit(SET_STATE, { isEditing: true, dialog: false })

      try {
        const response = await api.getEntidade('produtor', id)
        const produtorData = produtorTransformer(response.data)
        const model = response.data.cpf ? new ProdutorPF(produtorData) : new ProdutorPJ(produtorData)

        await dispatch('form/BOOTSTRAP_FORM', { model }, { root: true })

        const fazendas = rootState.form.data.fazendas || []
        const cadastros = fazendas.map(({ id, cadastro }) => ({
          id: cadastro.id,
          fazenda_id: id,
          cod_produtor_cliente: cadastro.cod_produtor_cliente ? cadastro.cod_produtor_cliente.toString() : '',
          cod_produtor_fornecedor: cadastro.cod_produtor_fornecedor ? cadastro.cod_produtor_fornecedor.toString() : '',
          tecnico_id: cadastro.tecnico_id ? cadastro.tecnico_id.toString() : '',
          ehNovo: false,
        }))

        commit(SET_STATE, {
          loading: false,
          picked: rootState.form.data.cpf ? 'fisica' : 'juridica',
          cadastros,
        })
      } catch (error) {
        console.error('Error loading producer data:', error)
        commit(SET_STATE, { loading: false })
      }
    } else {
      await dispatch('form/BOOTSTRAP_FORM', { model: new Produtor() }, { root: true })
      commit(SET_STATE, { dialog: true, loading: false })
    }
  },
  async [LOAD_FAZENDAS] ({ commit, state }) {
    commit(SET_STATE, { loading: true })
    await api.listEntidade('fazenda').then(
      fazendas => {
        commit(SET_STATE, { fazendas })
      },
    )
  },
  async [SUBMIT] ({ dispatch, commit, state, rootState }, { router, user }) {
    const form = { ...rootState.form.data }

    form.pessoaFis = (state.picked === 'fisica')

    if (form.pessoaFis) {
      const [day, month, year] = form.data_nascimento.split('/')
      form.data_nascimento = year + '-' + month + '-' + day
    }

    form.cadastros = state.cadastros || []
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        (() => {
          return (
            state.isEditing || state.isProdutor
              ? api.salvarEntidade(form, 'produtor', form.id)
              : api.cadastrarEntidade(form, 'produtor')
          )
        })().then(data => {
          // this.api.fakeRequest({ resolve: false, data: { message: 'Vixi' } }).then(data => {
          Swal.fire({
            title: (state.isEditing || state.isProdutor)
              ? 'Produtor atualizado com sucesso!'
              : 'Produtor cadastrado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
          dispatch('produtores/BOOTSTRAP', null, { root: true })
          router.push({ path: `/${user.get().role}/produtores` })
        }, error => {
          commit(SET_STATE, { loading: false })
          Swal.fire({
            icon: 'error',
            title: 'Atenção...',
            text: error.errors ? error.errors : error.message,
          })
        })
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
