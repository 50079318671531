export class ProdutorPF {
  constructor ({
    id,
    cpf,
    rg,
    nome,
    email,
    data_nascimento,
    sexo,
    telefones,
    pais,
    estado,
    cidade,
    bairro,
    numero,
    logradouro,
    cep,
    complemento,
    fazendas,
  } = {
    id: '',
    cpf: '',
    rg: '',
    nome: '',
    email: '',
    data_nascimento: '',
    sexo: '',
    telefones: [],
    pais: '',
    estado: '',
    cidade: '',
    bairro: '',
    numero: '',
    logradouro: '',
    cep: '',
    complemento: '',
    fazendas: [],
  }) {
    this.id = id
    this.cpf = cpf
    this.rg = rg
    this.nome = nome
    this.email = email
    this.data_nascimento = data_nascimento
    this.sexo = sexo
    this.telefones = telefones
    this.pais = pais
    this.estado = estado
    this.cidade = cidade
    this.bairro = bairro
    this.numero = numero
    this.logradouro = logradouro
    this.cep = cep
    this.complemento = complemento
    this.fazendas = fazendas
  }
}

export function createProdutorPF (data) {
  return Object.freeze(new ProdutorPF(data))
}
