<template>
  <v-card
    outlined
    class="px-6 mb-3 pt-4"
    style="margin-top: 14px; border-radius: 16px"
  >
    <form-fazenda-dialog
      v-model="dialogFazenda"
      @new-fazenda="novaFazenda($event)"
    />

    <v-card-title
      class="pa-0"
      style="text-shadow: grey 0.1em 0.1em 0.2em;"
    >
      {{ title }}
    </v-card-title>

    <v-form
      ref="form"
    >
      <v-card
        v-for="(cad, index) in cadastros"
        :key="cad.id"
        outlined
        class="pa-5 mb-2 mt-2"
        track-by="cad.id"
        style="border-radius: 16px"
      >
        <v-row>
          <v-col>
            <v-autocomplete
              ref="selectFazenda"
              v-model="cad.fazenda_id"
              :items="fazendas"
              item-text="razao_social"
              item-value="id"
              label="Fazenda*"
              :rules="[
                rules.required,
                rules.distinct(
                  cadastros.map(v => v.fazenda_id),
                  'Esta Fazenda já foi cadastrada para este Produtor.',
                ),
              ]"
              :loading="$asyncComputed.fazendas.updating"
              autocomplete="off"
              validate-on-blur
              dense
              :disabled="!cad.ehNovo"
              outlined
              rounded
            >
              <template
                #item="{ item }"
              >
                <v-container
                  class="rounded-lg mx-n2"
                  fluid
                >
                  <v-row
                    dense
                    justify="space-between"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.razao_social }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.cnpj }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-row>
                </v-container>
              </template>
              <template
                slot="prepend-item"
              >
                <v-btn
                  class="text-none text-white"
                  color="success"
                  dark
                  small
                  block
                  rounded
                  @click="() => { dialogFazenda = true, currentCard = index }"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-plus
                  </v-icon>
                  Cadastrar Nova Fazenda
                </v-btn>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-text-field
              v-model="cad.cod_produtor_cliente"
              v-mask="'####################'"
              dense
              autocomplete="off"
              :rules="[
                rules.required,
                rules.distinct(
                  cadastros.map(v => v.cod_produtor_cliente),
                  'Este código já foi utilizado.',
                ),
              ]"
              label="Código do Produtor Cliente*"
              validate-on-blur
              :disabled="!cad.ehNovo"
              outlined
              rounded
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-text-field
              v-model="cad.cod_produtor_fornecedor"
              v-mask="'####################'"
              dense
              autocomplete="off"
              :rules="[
                rules.required,
                rules.distinct(
                  cadastros.map(v => v.cod_produtor_fornecedor),
                  'Este código já foi utilizado.',
                ),
              ]"
              label="Código do Produtor Fornecedor*"
              validate-on-blur
              :disabled="!cad.ehNovo"
              outlined
              rounded
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="py-0"
          >
            <v-autocomplete
              v-model="cad.tecnico_id"
              :items="tecnicos"
              :item-text="(item) => item.nome || item.razao_social"
              item-value="id"
              label="Técnico *"
              :loading="$asyncComputed.tecnicos.updating"
              autocomplete="off"
              :rules="[rules.required]"
              validate-on-blur
              outlined
              rounded
              dense
            />
          </v-col>
        </v-row>
        <v-card-actions class="py-0 mt-2 mx-0">
          <v-spacer />
          <v-btn
            small
            color="red darken-3"
            class="text-none text-white justify-end"
            dark
            rounded
            title="Excluí a fazenda do produtor"
            :disabled="cadastros.length <= min"
            @click="removeEspacoCad(cad.id)"
          >
            <v-icon small>
              mdi-trash-can-outline
              left
            </v-icon>
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-btn
      class="text-none text-white mx-2 my-2"
      small
      dark
      color="success"
      title="Inclui uma nova Fazenda."
      rounded
      @click="addEspacoCad"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
      Incuir nova fazenda
    </v-btn>
  </v-card>
</template>
<script>
  import FormFazendaDialog from './FormFazendaDialog'
  import { v4 } from 'uuid'
  import rules from '../../utils/formRules'
  export default {
    components: {
      FormFazendaDialog,
    },
    model: {
      prop: 'cadastros',
      event: 'setCadastros',
    },
    props: {
      cadastros: {
        type: Array,
        default: () => [],
      },
      min: {
        type: Number,
        default: 0,
      },
      title: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        rules: rules,
        dialogFazenda: false,
        currentCard: null,
      }
    },
    asyncComputed: {
      fazendas: {
        get () {
          return this.api.listEntidade('fazenda').then(
            fazendas => fazendas,
            () => [],
          )
        },
        default: [],
      },

      tecnicos: {
        get () {
          return this.api.listEntidade('tecnico').then(response => response, () => [])
        },
        default: [],
      },
    },
    watch: {
      dialogFazenda: function (value) {
        if (!value) {
          this.$nextTick(() => {
            this.$refs.selectFazenda[this.currentCard].$el.focus()
          })
        }
      },
    },
    created () {
      if (this.cadastros.length < 1) {
        this.$emit('setCadastros', [
          {
            id: v4(),
            cod_produtor_fornecedor: null,
            cod_produtor_cliente: null,
            fazenda_id: null,
            tecnico_id: null,
            ehNovo: true,
          },
        ])
      }
    },
    methods: {
      novaFazenda ([{ fazenda: { id }, pessoaJuridica: { razao_social, cnpj } }]) {
        const index = this.currentCard
        this.fazendas.push({
          id,
          razao_social,
          cnpj,
        })

        this.cadastros[index].fazenda_id = id
        this.$refs.selectFazenda[index].isMenuActive = false
        this.$refs.selectFazenda[index].blur()
        this.$refs.form.resetValidation()
      },
      addEspacoCad () {
        if (this.$refs.form.validate()) {
          this.cadastros.push({
            id: v4(),
            cod_produtor_cliente: null,
            cod_produtor_fornecedor: null,
            fazenda_id: null,
            tecnico_id: null,
            ehNovo: true,
          })
        }
      },
      removeEspacoCad (id) {
        const index = this.cadastros.findIndex(v => v.id === id)
        this.cadastros.splice(index, 1)
      },
    },
  }
</script>
