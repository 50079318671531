export class ProdutorPJ {
  constructor ({
    id,
    cnpj,
    inscricao_estadual,
    nome_fantasia,
    razao_social,
    email,
    data_nascimento,
    sexo,
    telefones,
    pais,
    estado,
    cidade,
    bairro,
    numero,
    logradouro,
    cep,
    complemento,
    fazendas,
  } = {
    id: '',
    cnpj: '',
    inscricao_estadual: '',
    nome_fantasia: '',
    razao_social: '',
    email: '',
    data_nascimento: '',
    sexo: '',
    telefones: [],
    pais: '',
    estado: '',
    cidade: '',
    bairro: '',
    numero: '',
    logradouro: '',
    cep: '',
    complemento: '',
    fazendas: [],
  }) {
    this.id = id
    this.cnpj = cnpj
    this.inscricao_estadual = inscricao_estadual
    this.nome_fantasia = nome_fantasia
    this.razao_social = razao_social
    this.email = email
    this.data_nascimento = data_nascimento
    this.sexo = sexo
    this.telefones = telefones
    this.pais = pais
    this.estado = estado
    this.cidade = cidade
    this.bairro = bairro
    this.numero = numero
    this.logradouro = logradouro
    this.cep = cep
    this.complemento = complemento
    this.fazendas = fazendas
  }
}

export function createProdutorPJ (data) {
  return Object.freeze(new ProdutorPJ(data))
}
