<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <crud-cpf-dialog
      :dialog="dialog"
      :titulo="'Produtor'"
      :profile="'produtor'"
      :store="'produtores'"
      @close-dialog="setFields"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <crud-card title="Dados Principais">
          <v-container class="py-0">
            <v-row
              align="center"
              justify="center"
            >
              <v-radio-group
                v-model="picked"
                color="primary"
                row
                mandatory
              >
                <v-radio
                  value="fisica"
                  disabled
                  label="Pessoa Física"
                >
                  Pessoa Física
                </v-radio>
                <v-radio
                  value="juridica"
                  disabled
                  label="Pessoa Jurídica"
                >
                  Pessoa Jurídica
                </v-radio>
              </v-radio-group>
            </v-row>
          </v-container>
          <div v-if="picked==='fisica'">
            <crud-pessoa-fisica-base
              ref="pfBase"
              :optional="['rg']"
              :disabled="disabledFields.pfis"
              :input-outlined="true"
            />
            <crud-contato
              :telefones="telefones"
              :cols="6"
              :input-outlined="true"
            />
          </div>
          <div v-if="picked==='juridica'">
            <crud-pessoa-juridica-base
              ref="pjBase"
              :optional="['nome_fantasia']"
              :disabled="disabledFields.pjur"
            />
            <crud-contato
              :telefones="telefones"
              :input-outlined="true"
            />
          </div>
        </crud-card>

        <crud-localizacao
          title="Endereço Residencial"
          :optional="['bairro','logradouro']"
        />
        <card-cadastro-produtor-fazenda
          ref="cads"
          v-model="cadastros"
          title="Fazendas"
          :min="0"
        />
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="text-none text-white mr-3"
            color="error"
            rounded
            @click="$router.push({ path: `/${$user.get().role}/produtores` })"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="desabilitaBotao"
            :loading="loading"
            class="text-none text-white"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            {{ isEditing || isProdutor ? 'Salvar' : 'Cadastrar' }}
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import CardCadastroProdutorFazenda from './CardCadastroProdutorFazenda.vue'
  import rules from '../../utils/formRules'
  import ProdutorFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/produtor'
  import { mapState, mapActions } from 'vuex'
  export default {
    components: {
      CardCadastroProdutorFazenda,
    },
    data () {
      return {
        telefones: [
          { tipo: 'Comercial', format: 'com', title: 'Telefone 1', required: true },
          { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
        ],
        valid: false,
        rules: rules,
        loadedData: false,
        dialogFazenda: false,
        fazendasEdit: [],
        auxEdit: false,
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/produtor', ['fazendas', 'loading', 'isEditing']),
      ...mapFields('form/produtor', ['cadastros', 'dialog', 'picked', 'isProdutor']),
      disabledFields () {
        if (this.loaded || this.isEditing) {
          return { pfis: ['email', 'cpf'], pjur: ['cnpj', 'email'] }
        }
        return { pfis: ['cpf'], pjur: ['cnpj'] }
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.produtor) { this.$store.registerModule(['form', 'produtor'], ProdutorFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/produtor', [BOOTSTRAP_PROFILE, SUBMIT]),
      async setFields (data) {
        this.dialog = false

        if (data.value) {
          const { tipo, value } = data
          this.picked = tipo === 'cpf' ? 'fisica' : 'juridica'
          this.$store.commit('form/UPDATE_FORM_FIELD', { [tipo]: value })
          return
        }

        this.loadedData = true

        const id = data.id
        this.$router.push({
          path: `/${this.$user.get().role}/produtores/cadastro/`,
          query: { id },
        })
        this.BOOTSTRAP_PROFILE({ id })
      },
      submitForm () {
        if (!this.$refs.form.validate() || !this.$refs.cads.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
